
export default function formatQrExpireDate(timestamp: number, type: string): string {
    const thaiMonths = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
        'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
    ];

    const expireDate = new Date(timestamp);

    const hours = expireDate.getHours().toString().padStart(2, '0');
    const minutes = expireDate.getMinutes().toString().padStart(2, '0');
    const day = expireDate.getDate();
    const month = thaiMonths[expireDate.getMonth()];
    const year = expireDate.getFullYear() + 543; // แปลงเป็นปี พ.ศ.
    if (type === 'time') {
        return `${hours}:${minutes} น.`;
    } else if (type === 'date') {
        return `${day} ${month} ${year}`;
    } else {
        return `${hours}:${minutes} น. ${day} ${month} ${year}`;
    }
}
