// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../@types/api'
import api from '../../helpers/api'
import { requestUser } from '../../store/user/user.actions'
import { Button, Form, InputNumber, Typography } from 'antd'
import { ApplicationState } from '../../store'
import VerifyPhone from '../VerifyPhone/VerifyPhone'
import { makePath } from '../../helpers/path'
import NumberWithCommas from '../../helpers/NumberWithCommas'
import dayjs from 'dayjs'
const { Text } = Typography
interface WithdrawFormProps {
	initAmount: number
	paymentGateway: string
	minWithdraw: number
	credit: string
}
const WithdrawForm: FC<WithdrawFormProps> = ({ initAmount, paymentGateway, minWithdraw, credit }) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch()
	const history = useHistory()

	// ตัวอย่างเวลาเริ่มต้นและสิ้นสุด
	const startTime = '22:30';
	const endTime = '01:30';

	// แปลงเวลาเริ่มต้นและสิ้นสุดเป็นออบเจ็กต์ Day.js
	const start = dayjs().set('hour', parseInt(startTime.split(':')[0])).set('minute', parseInt(startTime.split(':')[1]));
	const end = dayjs().set('hour', parseInt(endTime.split(':')[0])).set('minute', parseInt(endTime.split(':')[1]));

	// เช็คว่าเวลาปัจจุบันอยู่ระหว่างช่วงเวลาหรือไม่ (แบบข้ามวัน)
	const isBetweenCrossDay = dayjs().isAfter(start) || dayjs().isBefore(end);
	const prefixBank = ['WK89', 'ALD88', 'ROMA911', 'LEXY88', '1XHENG', 'POKDENG']

	const [isSubmitting, setSubmitting] = useState<boolean>(false)

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const userState = useSelector((state: ApplicationState) => state.user)
	const prefix = prefixState.info, user = userState.info

	const [openVerifyPhone, setOpenVerifyPhone] = useState<boolean>(false)

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				if (userState.info && prefixState.info) {
					values.paymentGateway = paymentGateway ? paymentGateway : 'bank'
					if (!userState.info.phoneVerified && prefixState.info.functions.otp_withdraw) {
						setOpenVerifyPhone(true)
					} else {
						setSubmitting(true)
						try {
							const res = await api.post<void, APIResponse>(
								'/member/withdraw',
								values
							)
							dispatch(requestUser())
							if (prefix?.prefix !== 'CV88' &&
								prefix?.prefix !== 'NX98' &&
								prefix?.prefix !== 'RSM888' &&
								prefix?.prefix !== 'ALD88' &&
								prefix?.prefix !== 'WK89' &&
								prefix?.prefix !== 'LEXY88' &&
								prefix?.prefix !== 'ROMA911' &&
								prefix?.prefix !== 'POKDENG' &&
								prefix?.prefix !== '1XHENG'
							) {
								toast.success(res.message)
							} else {
								toast.success('ดำเนินการถอนสำเร็จ')
							}

							history.push(makePath('/transactions?tab=withdraw'))
						} catch (error: any) {
							toast.error(error.message as string)
						} finally {
							setSubmitting(false)
						}
					}
				}
			}).catch((errorInfo) => { });
	}

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': initAmount
			})
		}
	}, [form]) //eslint-disable-line

	const textWithdarw = `จำนวนเงินที่ถอน ${userState.vizpayWithdrawSetting !== undefined && userState.vizpayWithdrawSetting.displayFeeMemberStatus ? `(*มีค่าธรรมเนียมในการถอนครั้งนี้)` : ``}`

	return (
		<>
			<Form
				form={form}
				name="withdrawForm"
				layout="vertical"
				hideRequiredMark>
				<Form.Item
					name="amount"
					label={`${textWithdarw}  ( คงเหลือ ${credit} )`}
					rules={[{ required: true, message: 'กรุณากรอกจำนวนเงินที่ถอน' }]}
					style={{ marginBottom: 16 }}
					extra={`ขั้นต่ำ ${NumberWithCommas(minWithdraw, 2)}`}
				>
					<InputNumber
						placeholder="จำนวนเงินที่ถอน"
						inputMode="decimal"
						prefix="฿"
						controls={false}
						formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						style={{ width: '100%' }}
					/>
				</Form.Item>
				{/* {((prefix?.limitWithdrawNoBonus || 0) > 0) && (
					<Typography.Text type="secondary" style={{ fontSize: 24 }}>ไม่รับโปรโมชั่นถอนสูงสุดได้ {prefix?.limitWithdrawNoBonus} เท่า</Typography.Text>
				)} */}
				<div className="btn-submit">
					<Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting} onClick={handleSubmit} block>
						ยืนยันถอนเงิน
					</Button>
				</div>
			</Form>

			{isBetweenCrossDay && prefixBank.includes(prefix?.prefix as string) && (
				<div className="notice-card-w danger" style={{ marginTop: 20 }}>
					<div className="notice-card-icon">
						<img src="/icons/new/notice.svg" alt="notice icon" />
					</div>
					<Text className="notice-card-text">
						ถอนเงินได้ช่วงเวลา <Text type="danger"><strong> 01:30น. - 22:30น. </strong></Text>ทุกวัน
					</Text>
				</div>
			)}

			{/* Verify Phone */}
			{openVerifyPhone && (
				<VerifyPhone
					phone={userState.info?.phone || ''}
					isOpen={openVerifyPhone}
					prefixInfo={prefixState.info}
					onClose={() => setOpenVerifyPhone(false)} />
			)}
		</>
	)
}

export default WithdrawForm
