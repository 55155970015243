import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Peer2Pay from '../components/Deposit/Peer2Pay'
import BankQrCode from '../components/Deposit/BankQrCode'
import BankQrCodeOutTime from '../components/Deposit/BankQrCodeOutTime'
import TruewalletQRDialog from '../components/Deposit/TruewalletQRDialog'
import TruewalletVoucher from '../components/Deposit/TruewalletVoucher'
import UploadSlip from '../components/Deposit/UploadSlip'
import BankDeposit from './BankDeposit';
import BankSlipDeposit from './BankSlipDeposit';
// import BankDepositDecimal from '../components/Deposit/BankDepositDecimal';
import WalletDeposit from './WalletDeposit'
import DepositMethodButton from '../components/Deposit/DepositMethodButton'
import PromotionSlides from '../components/Deposit/PromotionSlides'
import { ApplicationState } from '../store'
import { requestDepositTo } from '../store/deposit/deposit.actions'
import { Col, Divider, Row } from 'antd'
import { Skeleton } from 'antd';
import dayjs from 'dayjs';

import { useAppContext } from '../contexts/AppContext';

const Deposit = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix.info)
	const depositState = useSelector((state: ApplicationState) => state.deposit)
	const depositToList = useSelector((state: ApplicationState) => state.deposit.depositToList)
	const depositToBanks = depositToList.filter((bank) => bank.bank !== 'truewallet')
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])


	const isBankEnable = depositState.depositToList.filter(({ bank }) => bank !== 'truewallet').length > 0
	const isTruewalletEnable = depositState.depositToList.filter(({ bank }) => bank === 'truewallet').length > 0
	const isPayonexStatus = prefix?.functions?.payonex;
	const isPeer2PayStatus = prefix?.peer2pay.enable;
	const [openBankDeposit, setOpenBankDeposit] = useState(false)
	const [openBankDepositSlip, setOpenBankDepositSlip] = useState(false)
	const [openBankQrCode, setOpenBankQrCode] = useState(false)
	const [openBankQrCodeOutTime, setOpenBankQrCodeOutTime] = useState(false)
	const [openWalletDeposit, setOpenWalletDeposit] = useState(false)
	const [openTruewalletQRDialog, setOpenTruewalletQRDialog] = useState(false)
	const [openTruewalletVoucher, setOpenTruewalletVoucher] = useState(false)
	const [openUploadSlip, setOpenUploadSlip] = useState(false)
	const [openPeer2Pay, setOpenPeer2Pay] = useState(false)

	// ตัวอย่างเวลาเริ่มต้นและสิ้นสุด
	const startTime = '23:59';
	const endTime = '00:05';

	// แปลงเวลาเริ่มต้นและสิ้นสุดเป็นออบเจ็กต์ Day.js
	const start = dayjs().set('hour', parseInt(startTime.split(':')[0])).set('minute', parseInt(startTime.split(':')[1]));
	const end = dayjs().set('hour', parseInt(endTime.split(':')[0])).set('minute', parseInt(endTime.split(':')[1]));

	// เช็คว่าเวลาปัจจุบันอยู่ระหว่างช่วงเวลาหรือไม่ (แบบข้ามวัน)
	const isBetweenCrossDay = dayjs().isAfter(start) || dayjs().isBefore(end);
	const { setIsOpenRedeemDialog } = useAppContext();


	// ตัวอย่างเวลาเริ่มต้นและสิ้นสุด
	const startTime2 = '22:30';
	const endTime2 = '01:30';

	// แปลงเวลาเริ่มต้นและสิ้นสุดเป็นออบเจ็กต์ Day.js
	const start2 = dayjs().set('hour', parseInt(startTime2.split(':')[0])).set('minute', parseInt(startTime2.split(':')[1]));
	const end2 = dayjs().set('hour', parseInt(endTime2.split(':')[0])).set('minute', parseInt(endTime2.split(':')[1]));

	// เช็คว่าเวลาปัจจุบันอยู่ระหว่างช่วงเวลาหรือไม่ (แบบข้ามวัน)
	const isBetweenCrossDay2 = dayjs().isAfter(start2) || dayjs().isBefore(end2);

	const textFreeCredit = `ทุกยอดฝาก รับฟรีเพิ่ม ${prefix?.peer2pay.amountBonus}${prefix?.peer2pay.typeBonus === "percent" ? "%" : " บาท"}`;
	const isPeer2payFreeCredit = prefix?.peer2pay.amountBonus === 0 ? "ฝากถอน 1 วินาที ฟรีค่าธรรมเนียม" : textFreeCredit;

	// console.log(depositToBanks)

	const handleBankDepositModal = () => {
		if (prefix?.functions.scanqr && depositToBanks.length === 1) {
			setOpenBankDepositSlip(true)
		} else {
			setOpenBankDeposit(true)
		}
	}

	const prefixBank = ['WK89', 'ALD88', 'ROMA911', 'LEXY88', '1XHENG', 'POKDENG']
	const bankSlipOnly = ['BBL', 'BAY', 'GSB']

	const titleBankDeposit = prefix?.functions.scanqr && depositState.depositToList.length === 1
		? (depositState.depositToList[0].hasSMS ? 'โอนผ่านธนาคาร' : 'ธนาคาร (อัปสลิป)')
		: 'โอนผ่านธนาคาร'

	if (!prefix || !depositState.isLoaded) {
		return (
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					<div className="deposit-skeleton">
						<Skeleton.Button active />
						<Skeleton.Button active />
						<Skeleton.Button active />
						<Skeleton.Button active />
					</div>
				</Col>
			</Row>
		)
	}

	return (
		<Fragment>
			<PromotionSlides />
			<Row justify="center">
				<Col xs={24} md={16} xl={12}>
					{info?.bankCode !== "TRUEWALLET" && isBankEnable &&
						(!prefixBank.includes(prefix?.prefix as string)) && (
							<div onClick={handleBankDepositModal}>
								<DepositMethodButton
									icon="icons/webp/bank.webp"
									title={titleBankDeposit}
								/>
							</div>
						)}

					{/* กรณีปกติ (ช่วงเปิดระบบ): เห็นได้ทุกธนาคาร ยกเว้น bankSlipOnly */}
					{info?.bankCode !== "TRUEWALLET" &&
						isBankEnable &&
						prefixBank.includes(prefix?.prefix as string) &&
						!isBetweenCrossDay2 &&
						!bankSlipOnly.includes(userState.info?.bankCode as string) && (
							<div onClick={handleBankDepositModal}>
								<DepositMethodButton
									icon="icons/webp/bank.webp"
									title={titleBankDeposit}
								/>
							</div>
						)}

					{/* กรณีช่วงปิดระบบ: เห็นได้ทุกธนาคาร */}
					{info?.bankCode !== "TRUEWALLET" &&
						isBankEnable &&
						prefixBank.includes(prefix?.prefix as string) &&
						isBetweenCrossDay2 && (
							<div onClick={handleBankDepositModal}>
								<DepositMethodButton
									icon="icons/webp/bank.webp"
									title={titleBankDeposit}
								/>
							</div>
						)}

					{/* (prefixBank.includes(prefix?.prefix as string) &&
							!bankSlipOnly.includes(userState.info?.bankCode as string)) && (
							<div onClick={handleBankDepositModal}>
								<DepositMethodButton
									icon="icons/webp/bank.webp"
									title={titleBankDeposit}
								/>
							</div>
						) */}
					{/* <div onClick={() => setOpenDepositDecimal(true)}>
						<DepositMethodButton
							icon="icons/webp/bank.webp"
							title="โอนผ่านธนาคาร"
							textRibbon="ฝากเงินรวดเร็ว 1 วินาที"
						/>
					</div> */}

					{/* {prefix?.prefix === "ALD88" || prefix?.prefix === "POK9" || prefix?.prefix === "9HENG88" ? (
						<>
							{info?.bankCode !== "TRUEWALLET" && isPayonexStatus && typeof isPayonexStatus !== 'undefined' && !isBetweenCrossDay && (
								<div onClick={() => setOpenBankQrCode(true)}>
									<DepositMethodButton
										icon="icons/webp/promtpay.webp"
										title="เติมผ่าน พร้อมเพย์"
									/>
								</div>
							)}
						</>
					) : ( */}
					{(info?.bankCode !== "TRUEWALLET" && isPayonexStatus && typeof isPayonexStatus !== 'undefined' && !isBetweenCrossDay && (
						<div onClick={() => setOpenBankQrCode(true)}>
							<DepositMethodButton
								icon="icons/webp/promtpay.webp"
								title="พร้อมเพย์ (QR Code)"
								subTitle="เปิด 01:30น. - 22:30น. ทุกวัน"
							/>
						</div>
					))}
					{/* {prefix.peer2pay && ( */}
					{isPeer2PayStatus && (
						<div onClick={() => setOpenPeer2Pay(true)} className="p2p">
							<DepositMethodButton
								icon="icons/webp/peer2pay.webp"
								title="Peer2Pay"
								subTitle="ฝากเงินรวดเร็ว 1 วินาที"
								textRibbon={isPeer2payFreeCredit}
							/>
						</div>
					)}

					{isTruewalletEnable && (
						<div onClick={() => setOpenWalletDeposit(true)}>
							<DepositMethodButton
								icon="icons/webp/true-wallet.webp"
								title="โอนผ่านทรูวอลเล็ท"
							/>
						</div>
					)}

					{depositState.truewalletQREnable && (
						<div onClick={() => setOpenTruewalletQRDialog(true)}>
							<DepositMethodButton
								icon="icons/webp/qr-code.webp"
								title="ทรูวอลเล็ท (QR Code)"
							/>
						</div>
					)}

					{depositState.truewalletVoucherEnable && (
						<div onClick={() => setOpenTruewalletVoucher(true)}>
							<DepositMethodButton
								icon="icons/webp/voucher.webp"
								title="ทรูวอลเล็ท (อั่งเปา)"
							/>
						</div>
					)}

					{depositState.redeemEnable && (
						<div onClick={() => setIsOpenRedeemDialog(true)}>
							<DepositMethodButton
								icon="icons/webp/coupon.webp"
								title="เติมโค้ดคูปอง"
							/>
						</div>
					)}


					{prefix?.functions.scanqr && info?.bankCode !== "TRUEWALLET" && isBankEnable &&
						(!prefixBank.includes(prefix?.prefix as string)) && (
							<>
								<Divider />
								<div onClick={() => setOpenUploadSlip(true)}>
									<DepositMethodButton
										icon="icons/webp/transfer-warning.webp"
										title="อัปโหลดสลิป"
										subTitle="สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ"
										secondary
									/>
								</div>
							</>
						)}
					{/* กรณีปกติ (ช่วงเปิดระบบ): เห็นได้ทุกธนาคาร ยกเว้น bankSlipOnly */}
					{prefix?.functions.scanqr && info?.bankCode !== "TRUEWALLET" && isBankEnable &&
						prefixBank.includes(prefix?.prefix as string) &&
						!isBetweenCrossDay2 &&
						!bankSlipOnly.includes(userState.info?.bankCode as string) && (
							<>
								<Divider />
								<div onClick={() => setOpenUploadSlip(true)}>
									<DepositMethodButton
										icon="icons/webp/transfer-warning.webp"
										title="อัปโหลดสลิป"
										subTitle="สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ"
										secondary
									/>
								</div>
							</>
						)}
					{/* กรณีช่วงปิดระบบ: เห็นได้ทุกธนาคาร */}
					{prefix?.functions.scanqr && info?.bankCode !== "TRUEWALLET" && isBankEnable &&
						prefixBank.includes(prefix?.prefix as string) &&
						isBetweenCrossDay2 && (
							<>
								<Divider />
								<div onClick={() => setOpenUploadSlip(true)}>
									<DepositMethodButton
										icon="icons/webp/transfer-warning.webp"
										title="อัปโหลดสลิป"
										subTitle="สำหรับผู้ที่โอนเงินแล้วยังไม่เข้าระบบ"
										secondary
									/>
								</div>
							</>
						)}
				</Col>
			</Row>

			<BankDeposit isOpen={openBankDeposit} onClose={() => setOpenBankDeposit(false)} /> :
			<BankSlipDeposit isOpen={openBankDepositSlip} onClose={() => setOpenBankDepositSlip(false)} />
			{/* <BankDepositDecimal isOpen={openDepositDecimal} onClose={() => setOpenDepositDecimal(false)} onOpen={() => setOpenDepositDecimal(true)} /> */}
			<Peer2Pay isOpen={openPeer2Pay} onClose={() => setOpenPeer2Pay(false)} onOpen={() => setOpenPeer2Pay(true)} />
			<BankQrCode isOpen={openBankQrCode} onClose={() => setOpenBankQrCode(false)} onOpen={() => setOpenBankQrCode(true)} />
			<BankQrCodeOutTime isOpen={openBankQrCodeOutTime} onClose={() => setOpenBankQrCodeOutTime(false)} onOpen={() => setOpenBankQrCodeOutTime(true)} />
			<WalletDeposit isOpen={openWalletDeposit} onClose={() => setOpenWalletDeposit(false)} />
			<TruewalletQRDialog isOpen={openTruewalletQRDialog} onClose={() => setOpenTruewalletQRDialog(false)} />
			<TruewalletVoucher isOpen={openTruewalletVoucher} onClose={() => setOpenTruewalletVoucher(false)} />
			<UploadSlip isOpen={openUploadSlip} onClose={() => setOpenUploadSlip(false)} />
		</Fragment >
	)
}

export default Deposit
