import React, { FC, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makePath } from '../../helpers/path'
import { toast } from 'react-toastify'
import { ApplicationState } from '../../store'
import { getBankName, getBankColor, Banks, moneyPattern } from '../../helpers/bank'
import { format } from '../../helpers/number'
import { Button, Form, Modal, InputNumber, Typography, Row, Col, message, Card, Flex, Space, Divider } from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import api from '../../helpers/api'
import { vizpayOrderInquiry } from '../../@types/vizpay'
import BankQrCodeCapture from './BankQrCodeCapture'
import { Member } from '../../@types/member'
// import { CopyOutlined } from '@ant-design/icons';
import NumberWithCommas from '../../helpers/NumberWithCommas'
// import { QRPaymentName } from '../../helpers/QRPaymentName'
import formatQrExpireDate from '../../utils/time'
import { useDispatch } from 'react-redux';
import { requestUser } from '../../store/user/user.actions';
import QRCode from 'qrcode'

const { Text } = Typography

interface Props {
	isOpen: boolean
	onClose: () => void
	onOpen: () => void
}

const BankQrCode: FC<Props> = ({ isOpen, onClose, onOpen }) => {
	const history = useHistory();

	const dispatch = useDispatch()
	const [messageApi, contextHolder] = message.useMessage();
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [form] = Form.useForm();
	const [qrCodeModal, setQrCodeModal] = useState<boolean>(false);
	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [amount, setAmount] = useState<number>(0) // จำนวนเงินที่ต้องการเติม
	const [isNewProgress, setIsNewProgress] = useState<boolean>(false) // สถานะ : มีธุรกรรมที่กำลังดำเนินการอยู่
	const [isTimeOut, setIsTimeOut] = useState<boolean>(false)  // สถานะ : หมดเวลาทำรายการ
	const [isSuccess, setIsSuccess] = useState<boolean>(false)  // สถานะ : ทำรายการสำเร็จ
	const userState = useSelector((state: ApplicationState) => state.user)
	const info = userState.info
	const [timer, setTimer] = useState<number>(0);
	const [qrExpireTime, setQrExpireTime] = useState<number>(0);
	const [stoppedTimer, setStoppedTimer] = useState<boolean>(false);
	const [depositQRCode, setDepositQRCode] = useState<any>()
	const [qrCodeUUID, setQRCodeUUID] = useState<any>()
	const [depositNumberPayment, setDepositNumberPayment] = useState<string>("")
	// const [depositNamePayment, setDepositNamePayment] = useState<string>("")
	const reBalance = () => {
		dispatch(requestUser())
	}

	const secondsToMinutes = (seconds: number): string => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')} นาที`;
		return formattedTime;
	}

	const handleSubmit = async () => {
		setSubmitting(true)
		form.validateFields()
			.then(async (values: any) => {
				if (values.amount <= 0) {
					setSubmitting(false)
					return false
				}
				try {
					setAmount(values.amount)
					const res: any = await api.post('/deposit/payonex-qr', {
						confirmGenerateQR: isNewProgress,
						amount: values.amount
					})
					// if (res.redirect) {
					// 	toast.success(res.message as string)
					// 	window.open(res.redirect, "_blank")
					// }
					// setSubmitting(false)
					// onClose()

					localStorage.setItem("qr-pay", JSON.stringify(res))

					setSubmitting(false)
					onClose()

					const dateString = res.data.qrExpireTime
					const inDate = new Date(dateString)
					const currentDate = new Date()

					// // คำนวณระยะเวลาและแปลงให้อยู่ในรูปของวินาที
					const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
					const timeCurrent = timeInSeconds * -1
					const timeFinal = parseInt(timeCurrent.toString(), 10)

					setTimer(timeFinal)
					setQrExpireTime(dateString)

					setDepositQRCode(res.data)
					setQRCodeUUID(res.data.uuid)
					setDepositNumberPayment(res.phoneNumberOrID)
					// const NamePayment = QRPaymentName[res.phoneNumberOrID] || ""
					// setDepositNamePayment(NamePayment)
					setQrCodeModal(true)
					setStoppedTimer(false)
				} catch (error: any) {
					toast.error(error.message as string)
					setSubmitting(false)
				}
			}).catch((errorInfo) => {
				setSubmitting(false)
			});
	}

	const handleTimeOut = () => {
		localStorage.removeItem("qr-pay")
		setQrCodeModal(false)
		setIsTimeOut(false)
		onOpen()
		setIsNewProgress(true)
	}

	const onCloseProcess = () => {
		setStoppedTimer(true)
		setIsNewProgress(true)
		setIsTimeOut(false)
		localStorage.removeItem("qr-pay")
	}

	const getStatusProcess = (uuid: string) => {
		const res = api.post<vizpayOrderInquiry>('/deposit/payonex-qr-inquiry', {  // eslint-disable-line
			uuid: uuid
		}).then(data => {
			if (data.data.data.status === "SUCCESS") {
				reBalance()
				setStoppedTimer(true)
				setIsSuccess(true)
				localStorage.removeItem("qr-pay")
			}
			if (data.data.data.status === "EXPIRED") {
				onCloseProcess()
			}
		})
	}

	const getStatusNewProcess = (uuid: string) => {
		const res = api.post<vizpayOrderInquiry>('/deposit/payonex-qr-inquiry', {  // eslint-disable-line
			uuid: uuid
		}).then(data => {
			if (data.data.data.status === "PENDING") {
				const qrPay = localStorage.getItem("qr-pay")
				if (qrPay) {
					const parseQr = JSON.parse(qrPay as string)
					if (parseQr.data.uuid === uuid) {
						setSubmitting(false)
						onClose()

						const dateString = parseQr.data.qrExpireTime
						const inDate = new Date(dateString)
						const currentDate = new Date()

						// คำนวณระยะเวลาและแปลงให้อยู่ในรูปของวินาที
						const timeInSeconds = (currentDate.getTime() - inDate.getTime()) / 1000
						const timeCurrent = timeInSeconds * -1
						const timeFinal = parseInt(timeCurrent.toString(), 10)

						setTimer(timeFinal)
						setQrExpireTime(dateString)
						setDepositQRCode(parseQr.data)
						setQRCodeUUID(parseQr.data.uuid)
						setDepositNumberPayment(parseQr.phoneNumberOrID)
						// const NamePayment = QRPaymentName[parseQr.phoneNumberOrID] || ""
						// setDepositNamePayment(NamePayment)
						setQrCodeModal(true)
						setStoppedTimer(false)
						
					} else {
						onCloseProcess()
					}
				} else {
					onCloseProcess()
				}
			} else {
				setStoppedTimer(true)
				setIsSuccess(true)
				localStorage.removeItem("qr-pay")
			}
		})
	}

	const changeAmount = (amount: number) => {
		form.setFieldsValue({ amount: amount })
	}

	const copyAccountNumber = (number: any) => {
		navigator.clipboard.writeText(number)
		messageApi.open({
			type: 'success',
			content: 'คัดลอกเลขพร้อมเพย์สำเร็จ',
		});
	}

	useEffect(() => {
		if (qrCodeModal && !stoppedTimer) {
			const countdown = setInterval(() => {
				if (timer <= 0) {
					clearInterval(countdown);
					setIsTimeOut(true)
				} else {
					setTimer(timer - 1);
				}
			}, 1000);

			return () => clearInterval(countdown);
		}
	}, [qrCodeModal, timer, stoppedTimer]); // eslint-disable-line

	useEffect(() => {
		if (form) {
			form.setFieldsValue({
				'amount': amount
			})
		}

		if (isOpen) {
			const qrPay: any = localStorage.getItem("qr-pay")
			if (qrPay) {
				const parseQr = JSON.parse(qrPay)
				getStatusNewProcess(parseQr.data.uuid)
			} else {
				setIsNewProgress(true)
			}
		}

		if (qrCodeModal) {
			const getStatusInterval = setInterval(() => {
				getStatusProcess(depositQRCode.uuid)
			}, 5000);

			return () => clearInterval(getStatusInterval);
		}
	}, [isOpen, amount, form, qrCodeModal]) // eslint-disable-line

	useEffect(() => {
		if (!qrCodeModal || !depositQRCode?.qrCode) return;

		const generateQRCode = async () => {
			const canvas = canvasRef.current;
			if (!canvas) return;

			// กำหนดขนาด canvas
			canvas.width = 280;
			canvas.height = 200;

			const ctx = canvas.getContext('2d');
			if (!ctx) return;

			try {
				// สร้าง temporary canvas สำหรับ QR Code
				const qrCanvas = document.createElement('canvas');
				await QRCode.toCanvas(qrCanvas, depositQRCode.qrCode, {
					width: 200,
					margin: 0,
					color: {
						dark: '#000000',
						light: '#ffffff'
					}
				});

				// Clear canvas ก่อนวาด
				ctx.clearRect(0, 0, canvas.width, canvas.height);

				// วาด QR Code ลงบน main canvas
				ctx.drawImage(qrCanvas, (canvas.width - 200) / 2, 0, 200, 200);

				// เพิ่มข้อความเตือน
				ctx.save();
				ctx.translate(canvas.width / 2, canvas.height / 2);
				ctx.rotate((-25 * Math.PI) / 180);

				// วาดข้อความลายน้ำ 3 บรรทัด
				ctx.font = '18px Arial';
				ctx.fillStyle = 'rgba(255, 0, 0, 0.6)';
				ctx.textAlign = 'center';

				ctx.fillText('ระวังมิจฉาชีพ', 0, -30);
				ctx.fillText('ใช้สำหรับเติมเงินเกมส์', 0, -5);
				ctx.fillText('ออนไลน์เท่านั้น', 0, 20);

				ctx.restore();
			} catch (err) {
				console.error('Error generating QR code:', err);
			}
		};

		// เรียกใช้ฟังก์ชันสร้าง QR Code
		generateQRCode();

	}, [qrCodeModal, depositQRCode?.qrCode]); // เพิ่ม dependency ให้ครบถ้วน

	return (
		<>
			{contextHolder}

			{/* Step 1 */}
			<Modal
				className="ant-modal-deposit"
				open={isOpen && isNewProgress}
				title="เติมเงินผ่าน พร้อมเพย์"
				centered
				onCancel={() => { !isSubmitting && onClose(); onCloseProcess() }}
				footer={null}
			>
				<Form
					form={form}
					name="truewalletVoucherForm"
					layout="vertical"
					hideRequiredMark
				>
					<Row>
						<Col span={24}>
							<Form.Item
								name="amount"
								label="จำนวนเงินที่ต้องการเติม"
								rules={[
									{ required: true, message: 'กรุณากรอกจำนวนเงินที่ต้องการเติม' },
									{ type: 'number', min: 100, message: 'กรุณากรอกจำนวนเงินขั้นต่ำ 100 บาท' }
								]}
								style={{ marginBottom: 0 }}
							>
								<InputNumber
									placeholder="0"
									inputMode="numeric"
									prefix="฿"
									controls={false}
									formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={(value) => (typeof value === 'string' ? value.replace(/[^\d]/g, '') : '')}
									onKeyPress={(event) => {
										if (!/^[0-9.,\b]+$/.test(event.key) || event.key === '.' || event.key === ',') {
											event.preventDefault();
										}
									}}
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
						<Col span={24} style={{ marginTop: 10 }}>
							<Row gutter={[16, 8]}>
								{moneyPattern.map((value, index) => (
									<Col span={6} key={index}>
										<Button key={value} size='small' block onClick={() => changeAmount(value)}>{format(value, 0)}</Button>
									</Col>
								))}
							</Row>
						</Col>
					</Row>
					<Button
						type="primary"
						htmlType="submit"
						loading={isSubmitting}
						disabled={isSubmitting}
						onClick={handleSubmit}
						style={{ margin: '16px 0 12px' }}
						block
					>
						เติมเงิน
					</Button>
				</Form>
			</Modal >

			{/* Step 2 */}
			<Modal
				open={qrCodeModal}
				title="เติมเงินผ่าน พร้อมเพย์"
				footer={null}
				onCancel={() => { setQrCodeModal(false); setIsNewProgress(false) }}
				centered
			>
				{!isSuccess && !isTimeOut && (
					<>
						<div className="payments-main">
							<img src={"promptPay/thai_qr_payment2.png"} alt="qr-thai" style={{ borderRadius: ' 12px 12px 0 0', width: "100%", display: 'block', margin: '0 0 -2px 0', objectFit: 'cover', zIndex: 5 }} />
							<Card
								title={null}
								style={{ width: "100%" }}
							>
										{qrCodeUUID && (
									<div style={{ marginTop: 10, marginBottom:10, textAlign: 'center' }}>
										<div style={{ color: '#0e3e68', fontSize: 16, fontWeight: 'bold' }}>
											{qrCodeUUID}
										</div>
									</div>
								)}
								<canvas ref={canvasRef} width={256} height={256} style={{ margin: '0 auto', display: 'block' }} />
								{/* <img src={depositQRCode?.image} alt={`qrCode`} style={{ display: 'block', margin: '0 auto', width: 180 }} /> */}
						
								<Flex justify={'center'} align={'center'} style={{ padding: 10 }}>
									<Text type="secondary" style={{ color: "#ff0000" }}><small>สแกนภายใน {secondsToMinutes(timer)}</small></Text>
								</Flex>
								<Flex justify={'space-around'} align={'center'}
									style={{
										border: '1px solid rgb(232, 232, 232)',
										borderRadius: 15,
										padding: 10
									}}>
									<div>
										<Space direction='vertical'>
											<Text type="secondary" style={{ color: "#666" }}><small>QRCode หมดอายุเวลา</small></Text>
											<Text type="secondary" style={{ color: "#ff0000" }}><small>{formatQrExpireDate(qrExpireTime, 'datetime')}</small></Text>
										</Space>
									</div>
									<Divider type="vertical"
										style={{
											border: '1px solid rgb(232, 232, 232)',
											borderColor: 'rgb(232, 232, 232)'
										}} />
									<div>
										<Text style={{ color: "#000", fontSize: 28 }}>ยอดเงิน <strong style={{ color: '#03ab47' }}>{NumberWithCommas(Number(depositQRCode?.amount), 2)}</strong> บาท</Text>
									</div>
								</Flex>
								<div style={{ display: 'flex', margin: '12px -16px -16px -16px', borderTop: '1px solid #efefef' }}>
									<div style={{ color: '#212121', flex: '1', textAlign: 'center', padding: '12px 0', cursor: 'pointer' }} onClick={handleTimeOut}>
										<i className="fa-regular fa-refresh" style={{ fontSize: 16, marginRight: 4 }}></i> ทำรายการใหม่
									</div>
									{/* , borderLeft: '1px solid #efefef' */}
									<div style={{ color: '#212121', flex: '1', textAlign: 'center', padding: '12px 0' }}>
										<BankQrCodeCapture
											qrCodeUrl={depositQRCode?.qrCode}
											bankLogoUrl={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
											info={info as Member}
											amount={format(depositQRCode?.amount || amount, 2)}
											qrExpireTime={qrExpireTime}
										/>
									</div>
								</div>
							</Card>
						</div>

						<div className="notice-card danger" style={{ marginTop: 8, marginBottom: 8 }}>
							<Text className="notice-card-text" style={{ paddingLeft: 0 }}>
								ใช้ <span style={{ color: '#dc4446' }}>ชื่อ/เลขบัญชี</span> ที่สมัคร <span style={{ color: '#dc4446' }}>สแกน QR Code </span>เท่านั้น
							</Text>
						</div>
						<div className="bank-card" style={{ marginBottom: 8 }}>
							<div
								className="bank-card-logo"
								style={{ boxShadow: `0 8px 12px -4px ${getBankColor(info?.bankCode)}` }}
							>
								<img
									src={Banks.filter(bankName => bankName.key === info?.bankCode.toUpperCase())[0].image}
									alt={getBankName(info?.bankCode)}
								/>
							</div>
							<div className="bank-card-info">
								<div><small>{getBankName(info?.bankCode)}</small></div>
								<div className="bank-deposite-account">{info?.bankNumber}</div>
								<div><small>{info?.firstName} {info?.lastName}</small></div>
							</div>
						</div>
					</>
				)}

				{isSuccess && (
					// ทำรายการสำเร็จ
					<div style={{ textAlign: 'center', padding: '18px 0' }}>
						<CheckCircleOutlined style={{ color: '#33cb00', fontSize: 56, marginBottom: 16 }} />
						<div>
							<Text style={{ fontSize: 28 }}>รับเงินเข้าสำเร็จ {amount} บาท สำเร็จ</Text>
						</div>
						<div style={{ marginBottom: 12 }}>
							<Text type="secondary" style={{ fontSize: 28 }}><small>ระบบกำลังเติมเงินเข้ากระเป๋า กรุณารอสักครู่</small></Text>
						</div>
						<Button className="ant-btn-dark" onClick={() => history.push(makePath('/wallet'))}>กลับหน้าแรก</Button>
					</div>
				)}

				{isTimeOut && (
					// หมดเวลาทำรายการ
					<div style={{ textAlign: 'center', padding: '18px 0' }}>
						<ExclamationCircleOutlined style={{ color: '#dc0303', fontSize: 56, marginBottom: 16 }} />
						<div style={{ marginBottom: 12 }}>
							<Text>หมดเวลาทำรายการ</Text>
						</div>
						<Button className="ant-btn-dark" onClick={handleTimeOut}>ทำรายการใหม่</Button>
					</div>
				)}
			</Modal>
		</>
	)
}

export default BankQrCode
