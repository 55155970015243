import React, { FC, useEffect, useState } from 'react'
import { Button } from 'antd'
import { Member } from '../../@types/member'
import { getBankName } from '../../helpers/bank'
import QRCode from 'qrcode'
import formatQrExpireDate from '../../utils/time'

interface Props {
    qrCodeUrl: string
    bankLogoUrl: string
    info: Member
    amount: string
    qrExpireTime: number
}

const BankQrCodeCapture: FC<Props> = ({ qrCodeUrl, bankLogoUrl, info, amount, qrExpireTime }) => {
    const [loading, setLoading] = useState(false);
    const [dataUrl, setDataUrl] = useState<string | null>(null);

    // Helper function to load images
    const loadImage = (src: string): Promise<HTMLImageElement> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = src;
        });
    };

    // Helper function to generate QR code canvas
    const generateQRCodeCanvas = async (text: string): Promise<HTMLCanvasElement> => {
        const qrCanvas = document.createElement('canvas');
        await QRCode.toCanvas(qrCanvas, text, {
            width: 150,
            margin: 0,
            color: {
                dark: '#000000',
                light: '#ffffff'
            }
        });
        return qrCanvas;
    };

    const drawCanvas = async () => {
        try {
            setLoading(true);
            const canvas = document.createElement('canvas');
            canvas.width = 400;
            canvas.height = 700;

            const ctx = canvas.getContext('2d');
            if (!ctx) throw new Error('Cannot get canvas context');

            // Draw background
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw header background
            ctx.fillStyle = '#003366';
            ctx.fillRect(0, 0, canvas.width, 80);

            ctx.font = 'bold 28px Arial';
            ctx.fillStyle = 'white';
            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillText('THAI QR PAYMENT', canvas.width / 2, 40);

            // Draw QR Code
            const qrCanvas = await generateQRCodeCanvas(qrCodeUrl);
            const qrSize = 280;
            const qrX = (canvas.width - qrSize) / 2;
            const qrY = 120;
            ctx.drawImage(qrCanvas, qrX, qrY, qrSize, qrSize);

            // Add watermark text over QR Code
            ctx.save();
            ctx.translate(canvas.width / 2, qrY + qrSize / 2);
            ctx.rotate((-25 * Math.PI) / 180); // หมุน -25 องศา

            // วาดข้อความลายน้ำ 3 บรรทัด
            ctx.font = '18px Arial';
            ctx.fillStyle = 'rgba(255, 0, 0, 0.6)';
            ctx.textAlign = 'center';
            ctx.fillText('ระวังมิจฉาชีพ', 0, -30);
            ctx.fillText('ใช้สำหรับเติมเงินเกมส์', 0, -5);
            ctx.fillText('ออนไลน์เท่านั้น', 0, 20);

            ctx.restore();

            // Draw expiry info
            const boxY = qrY + qrSize + 40;

            // Draw expiry info box (left)
            ctx.fillStyle = '#333333';
            ctx.beginPath();
            ctx.roundRect(40, boxY, 160, 60, [10]);
            ctx.fill();

            ctx.font = '20px DB HelvethaicaX';
            ctx.fillStyle = '#999999';
            ctx.textAlign = 'left';
            ctx.fillText('QRCode หมดอายุเวลา', 50, boxY + 20);
            ctx.fillStyle = '#ff4444';
            ctx.fillText(formatQrExpireDate(qrExpireTime, 'datetime'), 50, boxY + 45);

            // Draw amount box (right)
            ctx.fillStyle = '#333333';
            ctx.beginPath();
            ctx.roundRect(210, boxY, 150, 60, [10]);
            ctx.fill();

            ctx.font = '20px DB HelvethaicaX';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'left';
            ctx.fillText(`ยอดเงิน ${amount} บาท`, 230, boxY + 35);

            // Draw warning message box (red box with text)
            const warningY = boxY + 70;
            ctx.fillStyle = '#2a1517';
            ctx.strokeStyle = '#b63124';
            ctx.beginPath();
            ctx.roundRect(40, warningY, canvas.width - 80, 45, [10]);
            ctx.fill();
            ctx.stroke();

            // Draw warning text
            ctx.font = '20px DB HelvethaicaX';
            ctx.textAlign = 'left';
            ctx.fillStyle = '#ff4444';
            ctx.fillText('ใช้', 55, warningY + 25);
            ctx.fillStyle = 'white';
            ctx.fillText('ชื่อ/เลขบัญชี', 75, warningY + 25);
            ctx.fillStyle = '#ff4444';
            ctx.fillText('ที่สมัคร', 150, warningY + 25);
            ctx.fillStyle = 'white';
            ctx.fillText('สแกน QR Code', 200, warningY + 25);
            ctx.fillStyle = '#ff4444';
            ctx.fillText('เท่านั้น', 300, warningY + 25);

            // Draw bank info box
            const bankY = warningY + 55;
            ctx.fillStyle = '#333333';
            ctx.beginPath();
            ctx.roundRect(40, bankY, canvas.width - 80, 80, [10]);
            ctx.fill();

            // Load and draw bank logo
            const bankLogo = await loadImage(bankLogoUrl);
            ctx.drawImage(bankLogo, 55, bankY + 10, 60, 60);

            // Draw bank info text
            ctx.textAlign = 'left';
            ctx.fillStyle = '#999999';
            ctx.font = '18px DB HelvethaicaX';
            ctx.fillText(getBankName(info?.bankCode), 125, bankY + 25);

            ctx.fillStyle = 'white';
            ctx.font = 'bold 24px DB HelvethaicaX';
            ctx.fillText(info?.bankNumber, 125, bankY + 45);

            ctx.fillStyle = '#999999';
            ctx.font = '18px DB HelvethaicaX';
            ctx.fillText(`${info?.firstName} ${info?.lastName}`, 125, bankY + 65);

            setDataUrl(canvas.toDataURL());
        } catch (error) {
            console.error('Error generating canvas:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        drawCanvas();
    }, [amount, bankLogoUrl, info?.bankCode, info?.bankNumber, info?.firstName, info?.lastName, qrCodeUrl, qrExpireTime]);

    const downloadImage = () => {
        if (!dataUrl || loading) return;

        setLoading(true);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `qrcode_${Date.now()}.png`;
        link.click();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            {dataUrl && <img src={dataUrl} alt="qr code" style={{ display: 'none' }} />}
            {loading ? (
                <span style={{ opacity: 0.5 }}>กำลังบันทึก...</span>
            ) : (
                <span onClick={downloadImage} className="save-qr">
                    <i className="fa-regular fa-arrow-down-to-line" style={{ fontSize: 16, marginRight: 4 }} />
                    บันทึก QR
                </span>
            )}
        </>
    );
};

export default BankQrCodeCapture