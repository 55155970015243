// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { truewalletVoucherDeposit } from '../../services/deposit'
import { Button, Form, Modal, Input, Image, Typography, Space } from 'antd'
import {
	InfoCircleOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from '@ant-design/icons';
const { Link, Text } = Typography

interface TruewalletVoucherProps {
	isOpen: boolean
	onClose: () => void
}

const TruewalletVoucher: FC<TruewalletVoucherProps> = ({ isOpen, onClose }) => {
	const [form] = Form.useForm();

	const [isSubmitting, setSubmitting] = useState<boolean>(false)
	const [visible, setVisible] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = async () => {
		form.validateFields()
			.then(async (values: any) => {
				setSubmitting(true)
				try {
					const res = await truewalletVoucherDeposit({
						voucher: values.voucher,
					})
					toast.success(res.message as string)
					onClose()
				} catch ({ message }: any) {
					toast.error(message as string)
				} finally {
					setTimeout(() => {
						setSubmitting(false)
					}, 3000);
				}
			}).catch((errorInfo) => { });
	}

	const pasteButton = (e: any) => {
		e.preventDefault();
		// navigator.clipboard.readText()
		// 	.then(text => {
		// 		console.log('Pasted content: ', text);
		// 		form.setFieldsValue({
		// 			'voucher': text
		// 		})
		// 	})
		// 	.catch(err => {
		// 		console.error('Failed to read clipboard contents: ', err);
		// 	})
		try {
			setLoading(true)
			// const pastedText = await navigator.clipboard.readText();
			// form.setFieldsValue({ 'redeemCode': pastedText });
			setTimeout(async () => {
				const text = await navigator.clipboard.readText();
				form.setFieldsValue({ 'voucher': text });
				setLoading(false)
			}, 500);
		} catch (error) {
			console.error('Failed to read clipboard data:', error);
			setLoading(false)
		}
	}

	useEffect(() => {
		if (form && isOpen) {
			form.resetFields()
			form.setFieldsValue({
				'voucher': ''
			})
			setLoading(false)
		}
	}, [form, isOpen]) //eslint-disable-line

	return (
		<>
			<Modal
				className="ant-modal-deposit"
				open={isOpen}
				title="เติมเงินผ่านซองทรูมันนี่"
				centered
				onCancel={() => !isSubmitting && onClose()}
				footer={[
					<Button
						key={'button-submit'}
						type="primary"
						htmlType="submit"
						loading={isSubmitting}
						disabled={isSubmitting}
						onClick={handleSubmit}
						block
					>
						ส่งลิงก์
					</Button>
				]}
			>
				<Form
					form={form}
					name="truewalletVoucherForm"
					layout="vertical"
					hideRequiredMark>
					<Text style={{ marginBottom: 4, display: 'block' }}>กรุณาวางลิงก์ที่คัดลอกจากทรูมันนี่วอลเล็ท</Text>
					<Link onClick={() => setVisible(true)} style={{ padding: '2px 0 10px', display: 'block' }}>
						<small>
							<InfoCircleOutlined style={{ fontSize: 16, marginRight: 8 }} />
							<span>วิธีเติมเงินผ่านซองทรูมันนี่</span>
						</small>
					</Link>
					<div style={{ position: 'relative', overflow: 'hidden' }}>
						<Form.Item
							name="voucher"
							noStyle
							rules={[{ required: true, message: 'กรุณากรอกลิงก์ซองทรูมันนี่' }]}
						>
							<Input inputMode='text' placeholder="กรอกลิงก์" style={{ paddingTop: '11.25px', paddingBottom: '11.25px' }} />
						</Form.Item>
						<Button type="link" className="paste-btn" onClick={pasteButton} onTouchEnd={pasteButton} loading={loading} disabled={loading} style={{ height: '100%' }}>วางลิงก์</Button>
					</div>
				</Form>
			</Modal>
			<Image
				width={200}
				style={{ display: 'none' }}
				src="https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-topup-new.png"
				preview={{
					visible,
					src: 'https://pub-7d1da7fbc6504d9f8100764b1195e0fc.r2.dev/assets/banner-topup-new.png',
					onVisibleChange: value => {
						setVisible(value);
					},
					toolbarRender: (
						_,
						{
							transform: { scale },
							actions: { onZoomOut, onZoomIn },
						},
					) => (
						<Space size={12} className="toolbar-wrapper">
							<ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
							<ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
						</Space>
					),
				}}
			/>
		</>
	)
}

export default TruewalletVoucher
