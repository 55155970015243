import React, { FC } from 'react'
import { SyncOutlined } from '@ant-design/icons';
interface StatusBadgeProps {
	status: string
}
const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
	if (status === 'pending') {
		return (<div className="tag-status --warning">รอดำเนินการ</div>)
	}
	if (status === 'proc_callb_apped' || status === 'queueing_payonex') {
		return (<div className="tag-status --warning"><SyncOutlined spin style={{ fontSize: 14 }} /> กำลังดำเนินการ</div>)
	}
	if (status === 'reject') {
		return (<div className="tag-status --danger">ไม่สำเร็จ</div>)
	}
	if (status === 'bank_duplicate') {
		return (<div className="tag-status --danger">ฝากผิดพลาด</div>)
	}
	return (<div className="tag-status --success">สำเร็จ</div>)
}

export default StatusBadge
